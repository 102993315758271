import Keycloak, {KeycloakConfig, KeycloakInitOptions, KeycloakInstance} from "keycloak-js";
import Vue from "vue";
import _axiosInstance from "@/plugins/axios";
export let keycloak: KeycloakInstance;
export let keycloakOptions: KeycloakConfig;
export let configOptions: any;
export async function config(): Promise<any> {
    const config = await _axiosInstance.get("/kc-config");
    keycloakOptions = {
        url: config.data.url,
        realm: config.data.realm,
        clientId: config.data.clientId,
        // onLoad: "check-sso",
        // checkLoginIframe: false
    };
    configOptions = config.data;
    keycloak = Keycloak(keycloakOptions);
    Vue.prototype.$keycloak = keycloak;
}