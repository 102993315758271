import {Component, Vue, } from "vue-property-decorator";
import _axiosInstance from "@/plugins/axios";
import {AccordionSection} from "@/components/AccordionSection";

@Component
export default class HtmlComponent extends Vue {

    public uniqueName = ""
    public description = ""
    public title = ""
    public contentKeys: string[] = []
    public content: Map<String,String> = new Map<String, String>()
    public contentFields = [];
    public pageId = null

    public accordionSections = Object.values(AccordionSection as any);
    public accordion: number[] = [];
    public breadcrumbs = [
        {
            text: 'Back to Search',
            disabled: false,
            to: '/',
        },
        {
            text: `Organization/Program #${this.$route.query.id}`,
            disabled: true
        }
    ]

    public loadContent(): void {
        _axiosInstance.get("api/content/" + this.$route.query.id)
            .then(response => {
                const content = response.data
                console.log(response.data)
                this.pageId = content.webResourceId
                this.uniqueName = content.uniqueName
                this.title = content.title
                this.content = content.jsonContent
                document.title = this.title
            })
            .catch(error => {
                console.log(error)
            });
    }

    public retrieveSectionEntries (section: string) {
        return this.contentFields.filter((field: any) => field.accordionSection === section)
    }
    public toggleAccordion(): void {
       this.accordion = this.accordion.length === 0 ? [...Array(this.accordionSections.length).keys()].map((k, i) => i) : [];
    }

    public mounted(): void{
        console.log(JSON.stringify(this.accordionSections))
        _axiosInstance.get("api/template").then(res => {
            this.contentFields = res.data.content[0].pageStructureJson.fields.filter((item:any) => item.display).sort((field:any) => {field.order})
        })
        this.loadContent()
    }

    public formattedList(array: string) {
        if(array.startsWith("[") && array.endsWith("]")) {
            return array.replace("[","").replace("]","")
        }else {
            return array
        }

    }

    public formattedItemTitle(originalTitle: string) {
        if(originalTitle == null || originalTitle == '') {
            return ""
        }else {
            return originalTitle + ': '
        }

    }
}