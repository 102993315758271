import {Component, Inject, Vue} from 'vue-property-decorator';
import Header from "@/shared/Header.vue";
import Footer from "@/shared/Footer.vue";

@Component({
    components: {
        Header,
        Footer
    },
})
export default class AppComponent extends Vue {
}