import {Component, Vue, Watch} from "vue-property-decorator";
import {LMap,
    LTileLayer,
    LGeoJson,
} from 'vue2-leaflet';

import mncounties from './mncounties.json'
import router from "@/router";
@Component({
    components: {
        LMap,
        LTileLayer,
        LGeoJson
    }
})
export default class MapComponent extends Vue {

    lazyActive = false;
    dialog = false;

    @Watch("dialog", {deep: true})
    dialogWatch(visible: any) {
        if(visible) {
            console.log(visible)
        }

    }
    bounds = [
        [43.0,-97.5 ],
        [49.5, -89.00]
    ]
    url= 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
    attribution= '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';

    // url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', //topo map
    // url: 'https://{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png', //OSM France
    maxZoom= 7;
    center= [46.392410, -94.636230];
    geojson=  mncounties;

    onEachFeature(feature: any, featureLayer: any) {
        console.log(feature.properties.NAME)
        featureLayer.bindTooltip(feature.properties.NAME)
        featureLayer.setStyle({stroke: 1.5,weight: 1.5})
        featureLayer.on({
            click: function (e: any) {
                router.push({name:"Home",query:{countySearch:feature.properties.NAME}});
                window.location.reload();
            },
            mouseover: function (e: any) {
                console.log(featureLayer)
                featureLayer.setStyle({fillColor: '#000000'})
            },
            mouseout: function (e: any) {
                featureLayer.setStyle({fillColor: '#3388ff'})
            }
        })
    }

}