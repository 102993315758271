import Vue from 'vue';
import VueRouter from 'vue-router';
import * as kc from "@/plugins/keycloak-config";

const Home = () => import('@/views/Home.vue');
import Landing from "@/components/landing.vue"
import Html from "@/components/html.vue"
import UpdateContent from "@/admin/update-content.vue"
import AdminContentList from "@/admin/admin-content-list.vue"
import ViewContent from "@/components/view-content.vue"
import Map from "@/components/Map.vue";
import {Authority} from "@/shared/Authority";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Landing,
        meta: {
            title: "Minnesota Thrives"
        }
    },
    {
        path: '/html',
        name: 'HTML View',
        component: Html,
        meta: {
            title: "HTML View"
        }
    },
    {
        path: '/admin-content-list',
        name: 'Admin Content List',
        component: AdminContentList,
        meta: {
            title: "Search for Content to Edit",
            role: Authority.ROLE_ADMIN
        }
    },
    {
        path: '/update-content',
        name: 'Update Content',
        component: UpdateContent,
        meta: {
            title: "Update Content",
            role: Authority.ROLE_ADMIN
        }
    },
    {
        path: '/view-content',
        name: 'View Content',
        component: ViewContent,
        meta: {
            title: "View Content"
        }
    },
    {
        path: '/map',
        name: 'map',
        component: Map,
        meta: {
            title: "MN County Map"
        }
    },
]

const router = new VueRouter({
    // mode: 'history',
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            let querySelectValue = "h1";
            if(to.hash === '#alphabet') {
                querySelectValue = "input[type=text]";
            }
            setTimeout(function(){
                const focused = document.querySelector(querySelectValue) as HTMLElement;
                if(focused) {
                    focused.setAttribute("tabindex","-1");
                    focused.focus();
                }
            },0)
            return {
                selector: querySelectValue,
                behavior: 'smooth',
            }
        }
    }
})

router.beforeResolve((to: any, from: any, next: any) => {
    document.title = `MN Dept. of Health: ${to.meta.title}`
    // console.log(">>>>>>>>>>>>>>>PATH: " +to.fullPath)
    if (to.meta.role != undefined) {
        if (kc.keycloak.authenticated) {
            if (!hasRole(to.meta.role)) {
                alert("NOT AUTHORIZED")
            } else {
                next();
            }
        } else {
            kc.keycloak.login({redirectUri: `${kc.configOptions.logoutRedirect}#${to.fullPath}`});
        }
    } else {
        next();
    }
})

function hasRole(role: string): boolean {
    return kc.keycloak.hasResourceRole(role, kc.keycloakOptions.clientId)
}

export default router
